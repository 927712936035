import ApiService from './ApiService';

const CodesService = {

  getCodesList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }
    return ApiService.get('/codes', updatedParams);
  },

  validate(codeId) {
    return ApiService.post(`/code/${codeId}/validate`);
  },

  cancel(codeId) {
    return ApiService.post(`/code/${codeId}/cancel`);
  },

  remove(codeId, password) {
    return ApiService.delete(`/code/${codeId}/remove?password=${password}`);
  },

  submit(data) {
    return ApiService.post(`/codes/submit`, data);
  },

  getSubmitPageData() {
    return ApiService.get(`/codes/submit`);
  },
};

export default CodesService
