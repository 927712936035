import Validate from "../Validate";
import Cancel from "../Cancel";
import DeleteCode from "../Delete";

function ActionButtons({record, afterUpdate}) {
  return <div className="actionButtonsGroup">
    {record.showValidate === true && <Validate codeId={record.id} afterUpdate={afterUpdate}/>}
    {record.showCancel === true && <Cancel codeId={record.id} afterUpdate={afterUpdate}/>}
    {record.status === "Error" && <DeleteCode codeId={record.id} code={record.code} afterUpdate={afterUpdate} />}
  </div>
}

export default ActionButtons;
